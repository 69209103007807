// @import "~/bootstrap/dist/css/bootstrap.min.css";

// @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

// @font-face {
//   font-family: "Poppins Medium";
//   src: url("../assets/fonts/Poppins/Poppins-Medium.ttf");
// }

// @font-face {
//   font-family: "Poppins SemiBold";
//   src: url("../assets/fonts/Poppins/Poppins-SemiBold.ttf");
// }

// @font-face {
//   font-family: "Poppins Regular";
//   src: url("../assets/fonts/Poppins/Poppins-Regular.ttf");
// }

// @font-face {
//   font-family: "Poppins Bold";
//   src: url("../assets/fonts/Poppins/Poppins-Bold.ttf");
// }

// ADD KANTUMRUY PRO FONT
@font-face {
  font-family: "KantumruyPro Medium";
  src: url("../assets/fonts/kantumruy_pro/static/KantumruyPro-Medium.ttf");
}

@font-face {
  font-family: "KantumruyPro SemiBold";
  src: url("../assets/fonts/kantumruy_pro/static/KantumruyPro-SemiBold.ttf");
}

@font-face {
  font-family: "KantumruyPro Regular";
  src: url("../assets/fonts/kantumruy_pro/static/KantumruyPro-Regular.ttf");
}

@font-face {
  font-family: "KantumruyPro Bold";
  src: url("../assets/fonts/kantumruy_pro/static/KantumruyPro-Bold.ttf");
}

html,
body {
  // font-family: "Poppins Regular";
  font-family: "KantumruyPro Regular";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // font-family: "Poppins Bold";
  font-family: "KantumruyPro Bold";
}
img {
  width: 100%;
}

.pr-2 {
  padding-right: 10px;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}
